export const ALL_CATEGORIES = [
  {
    value: '0',
    label: 'Liquid pen',
    picNums: 10,
    new: true,
    children: [
      {
        value: '0-1',
        label: 'Classic liquid pen',
        picNums: 3,
        new: true,
        desc: [],
      },
      {
        value: '0-2',
        label: 'liquid pen 01',
        picNums: 2,
        desc: [],
      },
      {
        value: '0-3',
        label: 'liquid pen 02',
        picNums: 1,
        desc: [],
      },
      {
        value: '0-4',
        label: 'liquid pen 03',
        picNums: 1,
        desc: [],
      },
      {
        value: '0-5',
        label: 'liquid pen 04',
        picNums: 3,
        desc: [],
      },
    ],
  },
  {
    value: '1',
    label: 'Caps & Headwear',
    picNums: 81,
    children: [
      {
        value: '1-1',
        label: 'Baseball Cap',
        picNums: 23,
        desc: [
          'Made from 108x56 100% Cotton',
          '6 Panel, Medium Profile, Structured Crown & Pte-Curved Visor',
          'Adjustable Self-Material Strap With Hook And Loop Closure',
          'Size  Adult  One Size Fit All',
          'Imprint Method Options  Silk Screen Printing, Heat Transfer, Embroidery, Blank',
          'Imprint Area  Depend on Artwork',
          'Color Options  Custom PMS color Match',
          'Production Time  20days Normally',
          'Packing Method  Individual Polybagged. 200pcs per Carton',
          'Country of Origin China / Port of Shipping Shanghai',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '1-2',
        label: 'Visors',
        picNums: 23,
        desc: [
          'Made from Spandex Nylon',
          'Unstructured Fit Conforms to Your Head for Lower Profile, Built-in HeatGear Sweatband Wicks Away Sweat to Keep You Cool & Dry',
          'Adjustable Closure',
          'Size  Adult',
          'Imprint Method Options  Silk Screen Printing, Heat Transfer, Embroidery, Blank',
          'Imprint Area  Depending on Artwork',
          'Color Options  Custom PMS color Match',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 200pcs per Carton',
          'Country of Origin China / Port of Shipping NingBo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '1-3',
        label: 'Beanies',
        picNums: 15,
        desc: [
          'Made from Acrylic/Nylon Blend',
          'Lightweight  Knit Fabric Provides Superior Comfort & Warmth',
          'Moisture Wicking, Wrinkle Resistant',
          'Size  Adult  One Size Fit All',
          'Imprint Method Options  Embroidery, Blank',
          'Imprint Area  Depend on Artwork',
          'Color Options  Custom PMS color Match',
          'Production Time  20days normally',
          'Packing Method  Individual Poly Bagged. 200pcs per Carton',
          'Country of Origin China / Port of Shipping Shanghai, Ningbo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '1-4',
        label: 'Bucket Hat',
        picNums: 20,
        desc: [
          'Made from Cotton with Elastane',
          'Metal Eyelet for breathable, bendable brim',
          'Long Cord with Plastic Lock',
          'Size  Adult',
          'Imprint Method Options  Embroidery, Silk Printing,  Blank',
          'Imprint Area  Depending on Artwork',
          'Color Options  Custom PMS color Match',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 200pcs per Carton',
          'Country of Origin China / Port of Shipping Shenzhen',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
    ],
  },
  {
    value: '2',
    label: 'Mugs & Drinkware',
    picNums: 88,
    new: true,
    children: [
      {
        value: '2-1',
        label: 'Mug',
        picNums: 12,
        desc: [
          '16oz Capacity  Size 4"W x 4 1/2"H',
          'Mug with a Glossy Finish and Trendy Gradient Pattern',
          'Large C Handle',
          'Imprint Method Options:  Full Color, Silk Screen Printing, Blank',
          'Imprint Area  3 1/4"W x 1 1/8"H',
          'Color Options  Custom PMS Color Match',
          'Production Time  20days Normally',
          'Packing Method  Individual White Box, Packing 36pcs per Carton',
          'Country of Origin  China / Port of Shipping  Qingdao, Xiamen',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '2-2',
        label: 'Tumbler mug',
        picNums: 20,
        new: true,
        desc: [
          'Made from Stainless Steel FDA approved',
          'The lid includes a slide function to allow easy access to your drink',
          'BPA free,  Hand wash only',
          'Size   8 " x 4 " x 4 " Capacity is 30oz',
          'Imprint Method Options  Silk Screen Printing, Blank',
          'Imprint Area  3"W x 3"H / Wraparound 6"W x 3"H',
          'Color Options  Custom PMS color Match',
          'Production Time  25days normally',
          'Packing method  Individual poly bagged. 24pcs per carton',
          'Country of Origin China  Port of Shipping NingBo',
          'Customized option  materials, colors, design, Imprint, packing are available',
        ],
      },
      {
        value: '2-3',
        label: 'Sports bottle',
        picNums: 12,
        desc: [
          'Made from plastic',
          'Leak-Resistant Push Pull Lid, BPA Free',
          'Not For Hot Liquid Use, Hand Wash Recommended',
          'Size  8.5" H x 3" Dia.  Capacity is 24oz',
          'Imprint Method Options   Silk Screen Printing, Blank',
          'Imprint Area  4" W x 3" H',
          'Color Options  Custom PMS color Match',
          'Production Time  25days normally',
          'Packing method  Individual poly bagged. 50pcs per carton',
          'Country of Origin China  Port of Shipping NingBo',
          'Customized option  materials, colors, design, Imprint, packing are available',
        ],
      },
      {
        value: '2-4',
        label: 'Disposable Cups',
        picNums: 7,
        desc: [
          'Made from paper',
          'Offers simple or colorful design with plenty of space for a print of your choice',
          'Can be used for Hot or Cold Beverage',
          'Size  up to 8oz',
          'Imprint Method Options  Silk Screen Printing, Blank',
          'Imprint Area  2.5 x 2.25',
          'Color Options  White or Custom PMS color Matching',
          'Production Time  25days normally',
          'Packing method  Individual poly bagged. 1000pcs per carton',
          'Country of Origin China  Port of Shipping NingBo',
          'Customized option  materials, colors, design, Imprint, packing are available',
        ],
      },
      {
        value: '2-5',
        label: 'Glassware',
        picNums: 7,
        desc: [
          'Made from glasses',
          'Deep Etchings allow for Long Lasting Artwork',
          'A Great Glass For Gatherings With Friends',
          'Size  up to 16oz',
          'Imprint Method Options  Silk Screen Printing, Engraving, Decal Printing, Blank',
          'Imprint Area  2.5W x 2.5H',
          'Color Options  Custom PMS color Matching',
          'Production Time  25days normally',
          'Packing method  Individual poly bagged. 24pcs per carton',
          'Country of Origin China  Port of Shipping NingBo',
          'Customized option  materials, colors, design, Imprint, packing are available',
        ],
      },
      {
        value: '2-6',
        label: 'Mason Jars',
        picNums: 2,
        desc: [
          'Made from plastic',
          'Screw-On Lid And Matching 9" Straw',
          'Meets FDA Requirements, BPA Free, Hand Wash Recommended',
          'Size  6"H  Capacity is 25oz',
          'Imprint Method Options  Silk Screen Printing, Heat Transfer Printing, Blank',
          'Imprint Area  4"W x 2.5"H',
          'Color Options  Custom PMS color Matching',
          'Production Time  25days normally',
          'Packing method  Individual poly bagged. 60pcs per carton',
          'Country of Origin China  Port of Shipping NingBo',
          'Customized option  materials, colors, design, Imprint, packing are available',
        ],
      },
      {
        value: '2-7',
        label: 'Carafes',
        picNums: 2,
        desc: [
          'Made from glass',
          'Metal wire closure with plastic and rubber gasket',
          'Table Carafe Great for Restaurants and Special Events',
          'Size  12" H x 3.5" Base   Capacity is 34oz',
          'Imprint Method Options  Engraving, Silk Screen Printing, Blank',
          'Imprint Area  1.75"W x 2.75"H',
          'Color Options  Clear',
          'Production Time  25days normally',
          'Packing method  Individual poly bagged. 12pcs per carton',
          'Country of Origin China  Port of Shipping NingBo',
          'Customized option  materials, colors, design, Imprint, packing are available',
        ],
      },
      {
        value: '2-8',
        label: 'Straws & Stirrers',
        picNums: 7,
        desc: [
          'Made from Plastic',
          'Add Value to The Drinks You Serve With.',
          'Perfect for Promoting Drinks Brands, Pubs, Clubs and Restaurants.',
          'Size  160mm x 30mm',
          'Imprint Method Options  Screen Print, Full Color Sticker',
          'Imprint Area  27mm Dia.',
          'Color Options  Custom PMS Color Match',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 1000pcs per Carton',
          'Country of Origin China / Port of Shipping NingBo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '2-9',
        label: 'Can Coolers',
        picNums: 13,
        desc: [
          'Made from neoprene',
          'Folds Flat For Pocket Or Purse Storage',
          'Great for Outdoor Events',
          'Size  4 H or Custom made',
          'Imprint Method Options  Silk Screen Printing, Sublimation, Blank',
          'Imprint Area  3"W x 3"H',
          'Color Options  Custom PMS color Match.',
          'Production Time  25days normally',
          'Packing method  Individual poly bagged. 500pcs per carton',
          'Country of Origin China  Port of Shipping NingBo',
          'Customized option  materials, colors, design, Imprint, packing are available',
        ],
      },
      {
        value: '2-10',
        label: 'Protein Shakers',
        picNums: 2,
        desc: [
          'Made from Food-Grade Plastic',
          'The Blender Helps Mix Powder or Other Add-ins.',
          'Leak Proof, BPA Free.',
          'Size  210 x 85mm, Capacity is 20oz/600ml',
          'Imprint Method Options  Silk Screen Printing, UV Printing, Heat Transfer Printing, Blank',
          'Imprint Area  2" W x 3" H',
          'Color Options  Custom PMS Color Match.',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 72pcs per Carton',
          'Country of Origin China / Port of Shipping NingBo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '2-11',
        label: 'Protein Shakers',
        picNums: 2,
        desc: [
          'Made from Food-Grade Plastic',
          'The Blender Helps Mix Powder or Other Add-ins.',
          'Leak Proof, BPA Free.',
          'Size  210 x 85mm, Capacity is 20oz/600ml',
          'Imprint Method Options  Silk Screen Printing, UV Printing, Heat Transfer Printing, Blank',
          'Imprint Area  2" W x 3" H',
          'Color Options  Custom PMS Color Match.',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 72pcs per Carton',
          'Country of Origin China / Port of Shipping NingBo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '2-12',
        label: 'Thermos',
        picNums: 2,
        desc: [
          'Made from Stainless Steel, Plastic',
          'Double Wall Foam Insulation Retains Hot or Cold Temperatures',
          'Scratch-resistant Base',
          'Size   3" x 8" x 3"',
          'Imprint Method Options  Silk Screen Printing, Engraving, Blank',
          'Imprint Area  1.25" W x 1.5" H',
          'Color Options  Custom PMS Color Match.',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 24pcs per Carton',
          'Country of Origin China / Port of Shipping NingBo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
    ],
  },
  {
    value: '3',
    label: 'Sports',
    picNums: 23,
    children: [
      {
        value: '3-1',
        label: 'Balls',
        picNums: 4,
        desc: [
          'Made of PU leather',
          'Durable Construction and Responsive Touch',
          'Size  8.2" or Custom Made',
          'Imprint Method Options  Pad Printing, Blank',
          'Imprint Area  Depending on Artwork',
          'Color Options  Custom PMS Color Match.',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 50pcs per Carton',
          'Country of Origin China / Port of Shipping Shanghai',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '3-2',
        label: 'Cheers',
        picNums: 8,
        desc: [
          'Made from PE Plastic',
          'Making Bang Bang Noise in Game for Cheering',
          'LED Light Inside for Glowing',
          'Size  23.5" L x 3.9" W',
          'Imprint Method Options  Screen Printing, Blank',
          'Imprint Area  Depending on Artwork',
          'Color Options  Custom PMS Color Match.',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 500pairs per Carton',
          'Country of Origin China / Port of Shipping Shanghai',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '3-3',
        label: 'Sports towel',
        picNums: 9,
        desc: [
          'Made from Microfiber',
          'Lock Stitched on Edge.  Your Logo is Embroidered on Any Position.',
          'Size  30" x 18"',
          'Imprint Method Options   Embroidery, Blank',
          'Imprint Area  Depending on Artwork',
          'Color Options  Custom PMS Color Match.',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 200pcs per Carton',
          'Country of Origin China / Port of Shipping Shenzhen',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '3-4',
        label: 'Stadium Cups',
        picNums: 2,
        desc: [
          'Made from BPA Free Polypropylene',
          'Fits Most Auto Cup Holders. Full Wrap Image',
          'Size  22oz',
          'Imprint Method Options  Silk Screen Printing, Heat Transfer,  Blank',
          'Imprint Area  Depending on Artwork',
          'Color Options  Custom PMS Color Match.',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 500pcs per Carton',
          'Country of Origin China / Port of Shipping NingBo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
    ],
  },
  {
    value: '4',
    label: 'Travel Items',
    picNums: 27,
    children: [
      {
        value: '4-1',
        label: 'Toiletries',
        picNums: 11,
        desc: [
          'Made from PVC',
          'Clear See-All Cosmetic Bag',
          'Perfect Bag to Display Your Sample Gift Sets',
          'Size 6.5"L x 3"W x 4.75"H or Custom Made',
          'Imprint Method Options  Silk Screen Printing, UV Printing, Blank',
          'Imprint Area  3"W X 2"H',
          'Color Options  Clear',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 100pcs per Carton',
          'Country of Origin China / Port of Shipping NingBo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '4-2',
        label: 'Luggage',
        picNums: 12,
        desc: [
          'Made from Hard Plastic',
          'Metal Ring Attached',
          'Hard Strong Protect With Your Logo on It',
          'Size   1.2" H x 2.2" W',
          'Imprint Method Options  Silk Screen Printing or Blank',
          'Imprint Area   Depending on The Artwork',
          'Color Options  Stock Colors or  Custom PMS Color Match.',
          'Production Time  25days Normally',
          'Packing Method  1pc  per Poly Bagged. 500pcs per Carton',
          'Country of Origin China / Port of Shipping Shanghai',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '4-3',
        label: 'Travel Kits',
        picNums: 2,
        desc: [
          'Made from 600D Polyester and Polyester Lining',
          'Neck Pillow, Eye Mask, Ear Plugs',
          'Great For Travel, Home or Airline Gifts.',
          'Size  1.5 " x 3.75 " x 6 " for Bag',
          'Imprint Method Options  Silk Screen Printing, Blank',
          'Imprint Area  Depending on Artwork',
          'Color Options  Stock Color or PMS Matched',
          'Production Time  25days Normally',
          'Packing Method  1pc  per Poly Bagged. 100pcs per Carton',
          'Country of Origin China / Port of Shipping Shenzhen',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '4-4',
        label: 'Passport Holder',
        picNums: 2,
        desc: [
          'Made from Leather',
          'All-in-one Travel Wallet That can Fit All Your Essential Travel Documents',
          'Pockets for Passport, Tickets and Travel Documents',
          'Size  5"W x 9-3/4"H x 1-3/8"D',
          'Imprint Method Options   Debossed, Silk Screen Printing, Blank',
          'Imprint Area  Depending on Artwork',
          'Color Options  Stock Color or PMS Matched',
          'Production Time  25days Normally',
          'Packing method  Individual poly bagged. 100pcs per carton',
          'Country of Origin China  Port of Shipping Shanghai',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
    ],
  },
  {
    value: '5',
    label: 'Bar Items',
    picNums: 33,
    new: true,
    children: [
      {
        value: '5-1',
        label: 'Openers',
        picNums: 2,
        desc: [
          'Made from Aluminum',
          'Large Handle Area For Engraving',
          'Key Ring for Attached on Your Keys, Bags, or Belts',
          'Size  1.5"W x 4"H',
          'Imprint Method Options  Engraving, Silk Screen Printing, Blank',
          'Imprint Area  0.375" W x 1.5" H',
          'Color Options  Custom PMS Color Match',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 500pcs per Carton',
          'Country of Origin  China  / Port of Shipping  NingBo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '5-2',
        label: 'Bar Mats',
        picNums: 2,
        desc: [
          'Made from Silicone',
          'Eco-friendly, Non-toxic',
          '3D/2D embossed,debossed',
          'Size  Depend on Custom',
          'Imprint Method Options 3D/2D embossed,debossed',
          'Imprint Area  Depend on Custom',
          'Color Options  Custom PMS Color Match.',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 40pcs per Carton',
          'Country of Origin China / Port of Shipping NingBo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '5-3',
        label: 'Ice Buckets',
        picNums: 2,
        desc: [
          'Made from Metal Tin',
          'Constructed of Heavy Gauge Metal',
          'Great for Bars, Restaurant or Home for Fancy Parties.',
          'Size  9.5 Top Dia. x 7 Bottom Dia. x 7.5 H',
          'Imprint Method Options  Engraving, Silk Screen Printing, Blank',
          'Imprint Area  Depending on Your Artwork',
          'Color Options  Silver',
          'Production Time  25days normally',
          'Packing Method  Individual Poly Bagged. 25pcs per Carton',
          'Country of Origin  China  / Port of Shipping  NingBo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '5-4',
        label: 'Bar Caddies',
        picNums: 2,
        desc: [
          'Made from Wood',
          'Putting tissue, cocktail stirrer, spoon, bottle opener, pen and so on for home',
          'Storage Unit Pub Bar Tidy',
          'Size  Customized',
          'Imprint Method Options  Silk Printing, Lase ring, Blank',
          'Imprint Area  Depend om Custom',
          'Color Options Wooden',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 16pcs per Carton',
          'Country of Origin China / Port of Shipping NingBo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '5-5',
        label: 'Table Tents',
        picNums: 2,
        desc: [
          'Made from Acrylic Plastic',
          'Functional and Practical Way to Advertise',
          'Ideal in Retail, Hospitality, and Service Environments',
          'Size  4" W x 6" H or Custom Made',
          'Imprint Method Options  Silk Screen Printing, Full Color Printing, Blank',
          'Imprint Area  1.15" x 4"',
          'Color Options  Clear',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 100pcs per Carton',
          'Country of Origin  China / Port of Shipping  NingBo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '5-6',
        label: 'Chalk Boards',
        picNums: 1,
        desc: [
          'Made from Wood',
          'Perfect for Cafes and in-store Advertisements',
          'Portable and Easily Moved to Where Message Most Impact',
          'Size  43" x 24" or Custom Made',
          'Imprint Method Options  Silk Screen Print, Blank',
          'Imprint Area  2.5" x 5"',
          'Color Options  Custom PMS Color Match',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 5pcs per Carton',
          'Country of Origin  China  / Port of Shipping  NingBo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '5-7',
        label: 'Shakers',
        picNums: 2,
        desc: [
          'Made from',
          'BPA free, Food grade',
          'Newest style high quality stainless steel shaker bottle for bar cocktail',
          'Size  250ML, 350ML, 550ML, 750ML or Customized',
          'Imprint Method Options   Silk Screen Printing, Blank',
          'Imprint Area  Depend on Custom',
          'Color Options  Silver',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 20pcs per Carton',
          'Country of Origin China / Port of Shipping NingBo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '5-8',
        label: 'Pitchers',
        picNums: 2,
        desc: [
          'Made from Glass',
          'Food Grade Home and Bar Using Plastic Beer Pitcher',
          'High quality, competitive price and quick delivery time.',
          'Size  One Size',
          'Imprint Method Options Silk Screen Printing, Blank',
          'Imprint Area  Depend on Custom',
          'Color Options  Clear',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 12pcs per Carton',
          'Country of Origin China / Port of Shipping NingBo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '5-9',
        label: 'Shot Glasses & Jiggers',
        picNums: 2,
        desc: [
          'Made from Glass',
          'Colored Bottom Fit Your Logo Design',
          'Most Popular Shot Glass!',
          'Size  1.875"D (Top) x 1.125"D (Bottom) x 2.25"H  Capacity is 1.5oz',
          'Imprint Method Options  Engraving, Silk Screen Printing, Decal Printing, Blank',
          'Imprint Area  1.5"W x 1.125"H',
          'Color Options  Clear',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 144pcs per Carton',
          'Country of Origin  China  / Port of Shipping  NingBo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '5-10',
        label: 'Coasters',
        picNums: 16,
        desc: [],
      },
    ],
  },
  {
    value: '6',
    label: 'Pen',
    picNums: 39,
    new: true,
    desc: [],
  },
  {
    value: '7',
    label: 'Notebooks',
    picNums: 13,
    desc: [],
  },
  {
    value: '8',
    label: 'Magnets & Clips',
    picNums: 7,
    desc: [],
  },
  {
    value: '9',
    label: 'Stress Relievers',
    picNums: 7,
    desc: [],
  },
  {
    value: '10',
    label: 'Holders & Folders',
    picNums: 2,
    desc: [],
  },
  {
    value: '11',
    label: 'Lapel Pins',
    picNums: 2,
    desc: [],
  },
  {
    value: '12',
    label: 'Wristband',
    picNums: 6,
    new: true,
    children: [
      {
        value: '12-1',
        label: 'Silicone Wristband',
        picNums: 2,
        desc: [],
      },
      {
        value: '12-2',
        label: 'RFID smart wristband',
        picNums: 2,
        desc: [],
      },
      {
        value: '12-3',
        label: 'Tyvek wristband',
        picNums: 2,
        desc: [],
      },
    ],
  },
  {
    value: '13',
    label: 'Bags',
    picNums: 62,
    children: [
      {
        value: '13-1',
        label: 'Backpacks',
        picNums: 2,
        desc: [
          'Made of Water Resistant and Anti-Scratch oxford canvas material',
          'Perfect for business trips or weekend getaways or for simply heading to the office or school and home again',
          'Simplified design without extra decor. Neutral color, lightweight,sturdy but not real bulky',
          'Total 10 multiple compartments to meet your demand. 2 large compartment + 1 main compartment with thick foam on front for a laptop + 1 iPad pocket + 6 small(including the outer zipper pocket) for your things',
          'Exterior: 17" H x 13" W x 4" D .Ideal for Most Popular 11" ~ 15"',
          'Imprint Method Options:  leather patch, metal badge, Embroidery, blank',
          'Logo Area:  optional',
          'Color Options  Gray and Jean Blue, PMS color match',
          'Production Time  25days normally',
          'Packing method  Individual Poly bagged,  20pcs per carton',
          'Country of Origin  China,  Port of Shipping  NingBo, Shanghai',
          'Customized option, materials, colors, design, Imprint, packing are available',
        ],
      },
      {
        value: '13-2',
        label: 'Non woven Bags',
        picNums: 14,
        desc: [],
      },
      {
        value: '13-3',
        label: 'Drawstring bag',
        picNums: 10,
        desc: [
          'Made from Non-Woven Polypropylene Material',
          'Large carrying capacity, polyester corner with eyelet on bottom',
          'Drawstring closure',
          'Size  15" W x 16" L or custom size',
          'Imprint Method Options   Silk printing, Full color printing, Blank',
          'Imprint Area   Depend on your artwork',
          'Color Options  Custom PMS color Match',
          'Production Time  25days normally',
          'Packing method  Individual poly bagged. 100pcs per carton',
          'Country of Origin China  Port of Shipping Shanghai',
          'Customized option  materials, colors, design, Imprint, packing are available',
        ],
      },
      {
        value: '13-4',
        label: 'Cooler bag',
        picNums: 10,
        desc: [
          'Made from high density water proof polyester',
          'Leak resistant, one front pocket and 2 side mesh pocket, buckle secure on lid,',
          'Adjustable shoulder belt, strong handle on top with rubber grip.',
          'Size  8.25"W x 11.5"H x 5"D',
          'Imprint Method Options   Silk printing, Heat transfer, Embroidery, Blank',
          'Imprint Area  depend on artwork',
          'Color Options  Custom PMS color Match',
          'Production Time  25days normally',
          'Packing method  Individual poly bagged. 20pcs per carton',
          'Country of Origin China  Port of Shipping NingBo',
          'Customized option  materials, colors, design, Imprint, packing are available',
        ],
      },
      {
        value: '13-5',
        label: 'Fanny Packs',
        picNums: 6,
        desc: [
          'Made from polyester',
          'Zippered main compartment.',
          'Adjustable waist strap with buckle for putting on',
          'Size  14" W x 5.5" H',
          'Imprint Method Options  Silk printing, Hot stamp, Heat transfer, Embroidery, Blank',
          'Imprint Area  depend on artwork',
          'Color Options  Custom PMS color Match',
          'Production Time  25days normally',
          'Packing method  Individual poly bagged. 100pcs per carton',
          'Country of Origin China  Port of Shipping Shanghai',
          'Customized option  materials, colors, design, Imprint, packing are available',
        ],
      },
      {
        value: '13-6',
        label: 'Paper Bags',
        picNums: 3,
        desc: [
          'Made of white kraft',
          '13" W x 6" D x 15-3/4" H',
          'Twisted paper handle',
          'Imprint Mathod Options: Screen printing, Foil stamp, Blank',
          'Imprint Area:  9" x 7"',
          'Color Options: white, PMS color match',
          'Production Time  25days normally',
          'Packing method  100pcs per carton',
          'Country of Origin  China,  Port of Shipping  NingBo, Shanghai',
          'Customized option, materials, colors, design, Imprint, packing are available',
        ],
      },
      {
        value: '13-7',
        label: 'Plastic Bags',
        picNums: 2,
        desc: [
          'Made from recycled plastic',
          'Plastic bags with soft loop handles, reinforced fold-over tops, and bottom gussets',
          'Size  13"W x 15-1/2"H x 4"D',
          'Imprint Method Options  Screen Print, Foil Hot Stamp',
          'Imprint Area  depending on artwork',
          'Color Options  Custom PMS color Matching.',
          'Production Time  25days normally',
          'Packing method  Individual poly bagged. 200pcs per carton',
          'Country of Origin China  Port of Shipping Shanghai',
          'Customized option  materials, colors, design, Imprint, packing are available',
        ],
      },
      {
        value: '13-8',
        label: 'Cosmetic Bags',
        picNums: 4,
        desc: [
          'Made Of 210D Polyester',
          'Folds Down & Outside Zippered Compartment，One Mesh Pocket',
          'Buckle Closure, Zip Pocket Inside',
          'Size  Folded: 11"W x 6.5"H Hanging: 11"W x 20"H',
          'Imprint Method Options   Silk Printing, Heat Transfer, Embroidery, Blank',
          'Imprint Area  Depend on Artwork',
          'Color Options  Custom PMS Color Match',
          'Production Time  25days Normally',
          'PPacking Method  Individual Poly Bagged. 100pcs per Carton',
          'Country of Origin China / Port of Shipping Shanghai',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '13-9',
        label: 'Messager Bags',
        picNums: 5,
        desc: [
          'Made of 600D polyester, water-resistant nylon, polyester lining',
          'Larger compartment with flap cover, buckle closure, inside padded insert for protecting',
          'laptop, front zippered pocket',
          'Adjustable should strap',
          'Size  16"W x 12"H x 6"D',
          'Imprint Method Options  Silk printing, Logo Patch, Blank',
          'Imprint Area  depend on artwork',
          'Color Options  Custom PMS color Match',
          'Production Time  25days normally',
          'Packing method  Individual poly bagged. 20pcs per carton',
          'Country of Origin China  Port of Shipping Shanghai',
          'Customized option  materials, colors, design, Imprint, packing are available',
        ],
      },
      {
        value: '13-10',
        label: 'Computer Bags',
        picNums: 2,
        desc: [
          'Made of 600D Polyester',
          '16-1/2" W x 11" H x 3"D',
          'Padded sleeve accommodate laptops up to 15 inches.',
          'Large main compartment with a zippered closure',
          'Adjustable/removable shoulder strap.',
          'Imprint Method Options:  silk printing, full color printing, embroidery',
          'Imprint Area: both side of bag',
          'Color Options  Custom PMS color Match',
          'Production Time  25days normally',
          'Packing method  Individual poly bagged. 20pcs per carton',
          'Country of Origin  China, Port of Shipping  NingBo, Shanghai',
          'Customized option, materials, colors, design, Imprint, packing are available',
        ],
      },
      {
        value: '13-11',
        label: 'Duffle Bags',
        picNums: 4,
        desc: [
          'Made from 100% cotton canvas and leather exterior. 100% cotton lining interior.',
          '1 large zipper bag, 1 inside zip pocket, 1 outside zip pocket.',
          'Stylish top handle, Detachable and adjustable shoulder strap.',
          'Size  21"W x 12"H x 11"D or custom size',
          'Imprint Method Options Silk printing, Embroidery, Logo patch, Blank',
          'Imprint Area   depend on your artwork',
          'Color Options  Custom PMS color Match',
          'Production Time  25days normally',
          'Packing method  Individual poly bagged. 20pcs per carton',
          'Country of Origin China  Port of Shipping Shanghai',
          'Customized option  materials, colors, design, Imprint, packing are available',
        ],
      },
    ],
  },
  {
    value: '14',
    label: 'Outdoor Items',
    picNums: 12,
    children: [
      {
        value: '14-1',
        label: 'Foldable Director Chairs',
        picNums: 3,
        desc: [
          'Made From Steel and Poly/Mesh',
          'Steel Tubular Frame - Weight Limit 300 lbs',
          'Size   32"W x 34"H x 20"D',
          'Imprint Method Options  Silk Printing, Heat Transfer, Embroidery, Blank',
          'Imprint Area  Depending on Artwork',
          'Color Options  Custom PMS color Match.',
          'Production Time  25days normally',
          'Packing method  Individual poly bagged. 6pcs per carton',
          'Country of Origin China  Port of Shipping Shanghai',
          'Customized option  materials, colors, design, Imprint, packing are available',
        ],
      },
      {
        value: '14-2',
        label: 'Umbrellas',
        picNums: 5,
        desc: [
          'Made from Polyester and Steel Frame',
          'SPF of about UPF > 40 , AV <5 %',
          'Printing Color Changing After Wet',
          'Size  59cm length Pole, 98cmDia.',
          'Imprint Method Options  Silk Printing, Full Color Printing, Blank',
          'Imprint Area  Depending on Artwork',
          'Color Options  Custom PMS Color Match.',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 50pcs per Carton',
          'Country of Origin China / Port of Shipping Shanghai',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '14-3',
        label: 'Picnic blankets',
        picNums: 2,
        desc: [
          'Made from 100% Polyester Plaid Front with PVC Backing',
          'A Black Water and Sand Resistant Underside',
          'Comes with A Self-contained Carrying Case with A Black Shoulder Strap and An Outer Nylon Pocket',
          'Size 70" x 55"',
          'Imprint Method Options  Silk Printing, Embroidery, Blank',
          'Imprint Area  Depending on Artwork',
          'Color Options  Custom PMS Color Match.',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged.10pcs per Carton',
          'Country of Origin China / Port of Shipping Shanghai',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '14-4',
        label: 'BBQs',
        picNums: 2,
        desc: [
          'Made from Plated-steel',
          'Plated-steel Cooking Grate Evenly Distributes Heat Across 115-sq',
          'Electro-plated, Iron Body Ensures Durability for Season After Season of Grilling',
          'Size  11" W X 6" D X 11.5" H',
          'Imprint Method Options Laser Logo, Blank',
          'Imprint Area  Depending on Artwork',
          'Color Options  Custom PMS Color Match.',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 1pc per Carton',
          'Country of Origin China / Port of Shipping Shanghai',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
    ],
  },
  {
    value: '15',
    label: 'Automotive',
    picNums: 8,
    children: [
      {
        value: '15-1',
        label: 'Car Sunshades',
        picNums: 2,
        desc: [],
      },
      {
        value: '15-2',
        label: 'Air Fresheners',
        picNums: 2,
        desc: [
          'Made from Paper',
          'Fresh as a breeze, hang in your vehicle, home, or office for weeks of sensual',
          'Multiple Scent for Option',
          'Size   3" L x 4" W or Custom Size',
          'Imprint Method Options  Silk Printing, Full Color Printing, Blank',
          'Imprint Area  Full Area',
          'Color Options  Custom PMS Color Match.',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 1000pcs per Carton',
          'Country of Origin China / Port of Shipping Shanghai',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '15-3',
        label: 'Ice Scrapers',
        picNums: 2,
        desc: [
          'Made from Plastic and Metal',
          'Polycarbonate Blade on The Opposite End',
          'Handle is Molded With Up to 100% Recycled Material.',
          'Size  4-1/2" W x 36" L',
          'Imprint Method Options  Silk Printing, Blank',
          'Imprint Area  Depending on Artwork',
          'Color Options  Custom PMS Color Match.',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 100pcs per Carton',
          'Country of Origin China / Port of Shipping Shanghai',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '15-4',
        label: 'Bumper Sticker',
        picNums: 2,
        desc: [
          'Made from Self Adhesive Material',
          'Different Size and Design, Full Color Available',
          'Easy to Peel Off, No Mark on Your Car',
          'Size  Customized',
          'Imprint Method Options  Screen Printing, Full Color Printing',
          'Imprint Area  Depending on Artwork',
          'Color Options  Custom PMS Color Match.',
          'Production Time  25days Normally',
          'Packing Method  1000pcs per Carton',
          'Country of Origin China / Port of Shipping Shanghai',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
    ],
  },
  {
    value: '16',
    label: 'Keychains',
    picNums: 8,
    desc: [],
  },
  {
    value: '17',
    label: 'Lanyards',
    picNums: 5,
    desc: [],
  },
  {
    value: '18',
    label: 'Holiday Gifts',
    picNums: 7,
    children: [
      {
        value: '18-1',
        label: 'Party hats',
        picNums: 5,
        desc: [],
      },
      {
        value: '18-2',
        label: 'Christmas Santa Hats',
        picNums: 1,
        desc: [],
      },
      {
        value: '18-3',
        label: 'Christmas Felt Socks',
        picNums: 1,
        desc: [],
      },
    ],
  },
  {
    value: '19',
    label: 'Toys',
    picNums: 3,
    children: [
      {
        value: '19-1',
        label: 'Plush toys',
        picNums: 2,
        desc: [
          'Made from Plush, plush',
          '100%PP cotton',
          'wholesale or promotional gift',
          'Size customized',
          'Imprint Method Options Full Color Printing, Silk Screen Printing, Blank',
          'Imprint Area Depend on Custom',
          'Color Options  Custom PMS Color Match.',
          'Production Time  25days Normally',
          'Packing Method  Individual Poly Bagged. 20pcs per Carton',
          'Country of Origin China / Port of Shipping NingBo',
          'Customized Option  Materials, Colors, Design, Imprint, Packing are Available',
        ],
      },
      {
        value: '19-2',
        label: 'PVC Toys',
        picNums: 1,
        desc: [],
      },
    ],
  },
  {
    value: '20',
    label: 'Sweatband and Headband',
    picNums: 7,
    desc: [],
  },
  {
    value: '21',
    label: 'Banner',
    picNums: 1,
    new: false,
    desc: [],
  },
  {
    value: '22',
    label: 'Apron',
    picNums: 3,
    new: true,
    desc: [],
  },
  {
    value: '23',
    label: 'USB Flash Driver',
    picNums: 5,
    new: true,
    desc: [],
  },
  {
    value: '24',
    label: 'Sunglasses',
    picNums: 3,
    new: false,
    desc: [],
  },
  {
    value: '25',
    label: 'T-Shirt',
    picNums: 3,
    new: false,
    desc: [],
  },
  {
    value: '26',
    label: 'Jackets',
    picNums: 3,
    new: false,
    desc: [],
  },
  {
    value: '27',
    label: 'New Products',
    picNums: 103,
    new: true,
    children: [
      {
        value: '27-1',
        label: 'Travel cup with bottle opener',
        picNums: 4,
        desc: [],
      },
      {
        value: '27-2',
        label: 'Hand Phone wallet',
        picNums: 5,
        desc: [],
      },
      {
        value: '27-3',
        label: 'Wooden coaster with bottle opener',
        picNums: 4,
        desc: [],
      },
      {
        value: '27-4',
        label: 'Adjust Lanyard',
        picNums: 4,
        desc: [],
      },
      {
        value: '27-5',
        label: 'Thermal Mug with Magent Phone holder',
        picNums: 4,
        desc: [],
      },
      {
        value: '27-6',
        label: 'RFID Smart Wristbnad',
        picNums: 10,
        desc: [],
      },
      {
        value: '27-7',
        label: 'Tyvek bags',
        picNums: 6,
        desc: [],
      },
      {
        value: '27-8',
        label: 'New Sports bottle',
        picNums: 5,
        desc: [],
      },
      {
        value: '27-9',
        label: 'Double Sprite cup',
        picNums: 4,
        desc: [],
      },
      // 20241112 新增
      {
        value: '27-10',
        label: '3-1 Charging cable with Flashlight case',
        picNums: 2,
        desc: [],
      },
      {
        value: '27-11',
        label: '6-In-1 Quest Multi Tool Pen',
        picNums: 2,
        desc: [],
      },
      {
        value: '27-12',
        label: '20 Can Adjustable Backpack Cooler',
        picNums: 3,
        desc: [],
      },
      {
        value: '27-13',
        label: 'Branded Tag Along Multi-Tool',
        picNums: 6,
        desc: [],
      },
      {
        value: '27-14',
        label: 'Carabiner bottle with Cooler Towel',
        picNums: 3,
        desc: [],
      },
      {
        value: '27-15',
        label: 'Decompression Ballpen',
        picNums: 4,
        desc: [],
      },
      {
        value: '27-16',
        label: 'Funny Ice Ball Mold',
        picNums: 5,
        desc: [],
      },
      {
        value: '27-17',
        label: 'Intrepid Tumbler Accessory Pouch',
        picNums: 6,
        desc: [],
      },
      {
        value: '27-18',
        label: 'Laptop Swivel Phone Mount',
        picNums: 4,
        desc: [],
      },
      {
        value: '27-19',
        label: 'Promotional 4-in -1 Accordion Charging Cable',
        picNums: 2,
        desc: [],
      },
      {
        value: '27-20',
        label: 'Promotional 30-Piece Tool Kit With LED Light',
        picNums: 2,
        desc: [],
      },
      {
        value: '27-21',
        label: 'Promotional Big Beacon Light-Up Keychain with Opener',
        picNums: 4,
        desc: [],
      },
      {
        value: '27-22',
        label: 'Promotional Phone Stand Screen Clearner',
        picNums: 3,
        desc: [],
      },
      {
        value: '27-23',
        label: 'Silicone Lanyard With Phone Holder & Wallet',
        picNums: 6,
        desc: [],
      },
      {
        value: '27-24',
        label: 'Stressrelivers Phone Holder',
        picNums: 4,
        desc: [],
      },
      {
        value: '27-25',
        label: 'Eco-Friendly Data Cables',
        picNums: 3,
        desc: [],
      },
    ],
  },
];
