import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
import './theme/index.css'; // 浅绿色主题

import VueSeamlessScroll from 'vue-seamless-scroll';
// import 'vue-seamless-scroll/dist/vue-seamless-scroll.css';

import Home from './pages/Home.vue';
import Product from './pages/Product.vue';
import About from './pages/About.vue';
import Small from './pages/Small.vue';

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(ElementUI);

Vue.use(VueSeamlessScroll);

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    name: 'New',
    path: '/new',
    component: Home,
  },
  {
    name: 'About',
    path: '/about',
    component: About,
  },
  {
    name: 'Product',
    path: '/product',
    component: Product,
  },
];

const router = new VueRouter({
  routes, // (缩写) 相当于 `routes: routes`
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
