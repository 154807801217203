<template>
  <el-container class="page-container">
    <PageWrapper
      @scrollTo="smoothScrollTo"
      @drawer="handleDrawer"
      :selectedNum="selectedList.length"
      :isShow="false"
      @list="handleToList"
    >
      <template slot="main">
        <!-- 产品列表 -->
        <div id="product" class="title">
          <span>All Product Category</span>
        </div>
        <ProductList ref="listRef" @change-selected="handleChangeSelect" />
        <!-- 新产品 -->
        <div id="new-product" class="title">
          <span>New Featured Products</span>
        </div>
        <NewProduct />
        <!-- <ProductCloud /> -->
        <!-- 公司简介 -->
        <div class="title">
          <span>About Our Company</span>
        </div>
        <About id="about" />
      </template>
    </PageWrapper>
    <!-- 购物车抽屉 -->
    <el-drawer title="Your Shopping Cart" :visible.sync="showDrawer">
      <div v-for="item in selectedList" :key="item" class="drawer-item">
        <el-image
          style="width: 100px; height: 100px"
          :src="getObjectByPicIndex(item).url"
        >
        </el-image>
        <div class="info">
          <div class="text">
            <span class="label">CATEGORY</span>
            <span class="value">{{
              getObjectByPicIndex(item).categoryLabel
            }}</span>
          </div>
          <div v-if="getObjectByPicIndex(item).subCategoryLabel" class="text">
            <span class="label">SUB CATEGORY</span>
            <span class="value">{{
              getObjectByPicIndex(item).subCategoryLabel
            }}</span>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 常驻按钮 -->
    <div class="fixed-btn">
      <div class="shopping" @click="showDrawer = true">
        <div class="count">{{ selectedList.length }}</div>
        <div class="icon">
          <i class="el-icon-shopping-cart-full" />
        </div>
      </div>
      <div class="mail">
        <a
          class="content"
          href="mailto:miya@trppc.com.cn?subject=Hello The Right Promotional Products Company&body=Hello The Right Promotional Products Company!"
        >
          <i class="el-icon-message" />
        </a>
      </div>
      <div class="arrow" @click="handleTop">
        <i class="el-icon-arrow-up" />
      </div>
    </div>
  </el-container>
</template>

<script>
  import PageWrapper from '../components/PageWrapper.vue';
  import Search from '../components/Search.vue';
  import Banner from '../components/Banner.vue';
  import ProductList from '@/components/product/ProductList.vue';
  import NewProduct from '@/components/product/NewProduct.vue';
  import ProductCloud from '@/components/product/ProductCloud.vue';

  import About from '../components/about/About.vue';
  import { ALL_CATEGORIES } from '../components/constants.js';

  export default {
    name: 'Home',
    components: {
      PageWrapper,
      Search,
      Banner,
      ProductList,
      NewProduct,
      ProductCloud,
      About,
    },
    data() {
      return {
        showDrawer: false,
        selectedList: [],
      };
    },
    methods: {
      handleDrawer() {
        this.showDrawer = !this.showDrawer;
      },
      handleChangeSelect(list) {
        this.selectedList = list;
      },
      getObjectByPicIndex(picIndex) {
        const obj = {};
        const indexs = picIndex.split('-');
        if (indexs.length === 2) {
          obj.url = require(
            `../assets/image/category/${indexs[0]}/${picIndex}.jpg`
          );
          const category = ALL_CATEGORIES.find(
            item => item.value === indexs[0]
          );
          obj.categoryLabel = category.label;
        } else if (indexs.length === 3) {
          obj.url = require(
            `../assets/image/category/${indexs[0]}/${indexs[0]}-${indexs[1]}/${picIndex}.jpg`
          );
          const category = ALL_CATEGORIES.find(
            item => item.value === indexs[0]
          );
          obj.categoryLabel = category.label;
          const subCategory = category.children.find(
            item => item.value === `${indexs[0]}-${indexs[1]}`
          );
          obj.subCategoryLabel = subCategory.label;
        }
        return obj;
      },
      handleTop() {
        // window.scrollTo(0, 0);
        this.smoothScrollTo('home');
      },
      smoothScrollTo(targetId, duration = 500) {
        // 获取目标元素的位置
        const targetElement = document.getElementById(targetId);
        if (!targetElement) return;
        const targetPosition = targetElement.offsetTop;
        const startPosition = window.pageYOffset;
        const distance = targetPosition - startPosition;
        let startTime = null;

        function animation(currentTime) {
          if (startTime === null) startTime = currentTime;
          const timeElapsed = currentTime - startTime;
          const run = ease(timeElapsed, startPosition, distance, duration);
          window.scrollTo(0, run);
          if (timeElapsed < duration) {
            requestAnimationFrame(animation);
          }
        }

        // 缓动函数，这里使用的是简单的线性缓动
        function ease(t, b, c, d) {
          return c * (t / d) + b;
        }

        // 启动动画
        requestAnimationFrame(animation);
      },
      handleToList(array) {
        this.smoothScrollTo('product');
        if (array.length === 1) {
          this.$refs.listRef.activeName = array[0];
        }
        if (array.length === 2) {
          this.$refs.listRef.activeName = array[0];
          setTimeout(() => {
            this.$refs.listRef.subActiveName = array[1];
          }, 200);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .page-container {
    .title {
      display: flex;
      justify-content: center;
      width: 500px;
      height: 40px;
      background: url('~@/assets/image/home/title-bg.jpeg') no-repeat;
      margin: 40px auto 0;
      span {
        font-size: 26px;
        font-weight: 700;
        line-height: 14px;
      }
    }
    .drawer-item {
      display: flex;
      padding: 0 20px;
      border-bottom: 1px solid #808080;
      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 20px;
        width: 300px;
        .text {
          display: flex;
          justify-content: space-between;
          width: 100%;
          span {
            display: inline-block;
          }
          .label {
            color: #3b8043;
          }
          .value {
            color: #28a745;
          }
        }
      }
    }
    .fixed-btn {
      position: fixed;
      right: 8px;
      bottom: 12rem;
      width: 2rem;
      height: 180px;
      z-index: 99;
      .shopping {
        width: 2rem;
        height: 2rem;
        border-radius: 2rem;
        background: #81d742;
        color: #fff;
        margin-bottom: 8px;
        border: 1px solid #808080;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .icon {
          font-size: 20px;
          font-weight: 700;
          color: #fff;
        }
        .count {
          position: absolute;
          top: -12px;
          right: -6px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          background: #28a745;
          color: #fff;
          font-size: 12px;
        }
      }
      .mail,
      .arrow {
        width: 2rem;
        height: 2rem;
        border-radius: 2rem;
        background: #81d742;
        color: #fff;
        border: 1px solid #808080;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-bottom: 8px;
      }
      .mail {
        a {
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
</style>
