<template>
  <div class="page-wrapper">
    <!-- 头部固定 - 全局导航 -->
    <el-header v-if="isShow">
      <Header
        id="home"
        :selectedNum="selectedNum"
        @scrollTo="smoothScrollTo"
        @drawer="handleDrawer"
        @list="handleToList"
      />
    </el-header>
    <!-- 常驻：搜索、轮播图 -->
    <section>
      <!-- 分类搜索 -->
      <Search
        v-if="isShow"
        @drawer="handleDrawer"
        :selectedNum="selectedNum"
        @list="handleToList"
      />
      <!-- 轮播图 -->
      <Banner />
    </section>
    <el-main class="main" v-if="isShow">
      <slot name="main"></slot>
    </el-main>
    <el-footer>
      <Footer :isShow="isShow" />
    </el-footer>
  </div>
</template>

<script>
  import Header from './Header.vue';
  import Search from './Search.vue';
  import Banner from './Banner.vue';
  import Footer from './Footer.vue';

  export default {
    name: 'PageWrapper',
    props: {
      selectedNum: {
        type: Number,
        default: 0,
      },
      isShow: {
        type: Boolean,
        default: true,
      },
    },
    components: {
      Header,
      Search,
      Banner,
      Footer,
    },
    methods: {
      /**
       * smoothScrollTo - 用于实现平滑滚动到目标位置的函数
       *
       * 该函数目前为空，需要实现具体的滚动逻辑。
       * 可能需要考虑添加参数来指定滚动的目标位置，以及平滑滚动的速度等。
       *
       * @returns {void} - 该函数目前没有返回值。
       */
      smoothScrollTo(id) {
        this.$emit('scrollTo', id);
      },

      handleDrawer() {
        this.$emit('drawer');
      },
      handleToList() {
        this.$emit('list');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .page-wrapper {
    width: 100%;
    height: 100%;
    ::v-deep .el-header {
      padding: 0;
      height: 54px !important;
    }
    ::v-deep .el-main {
      max-width: 1520px;
      padding: 0 20px;
      margin: 0 auto;
    }
    ::v-deep .el-footer {
      padding: 0;
    }
  }
</style>
